/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer">False Positive</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">False Positive</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">False Positive</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">False Positive</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">False Positive</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mb-4">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-bold">Vulnerability</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-bold">Vulnerability Description</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-bold">Operating System</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-bold">Confirmed</h2>
                </div>
              </div>

              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Directory traversal</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">A vulnerability was found in the IIS server</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Linux</h2>
                </div>
                <div class="vx-col w-1/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0" />
                </div>
              </div>

              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Default credentials</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">User:admin Pass:admin on CISCO AP</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">IOS</h2>
                </div>
                <div class="vx-col w-1/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1" />
                </div>
              </div>

              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Weak SSH encryption</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">SSH clients can negotiate weak ciphers</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Windows</h2>
                </div>
                <div class="vx-col w-1/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2" />
                </div>
              </div>

              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Expired certificate</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">The RDP service certificate has expired</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Linux</h2>
                </div>
                <div class="vx-col w-1/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3" />
                </div>
              </div>

              <div class="vx-row border-white border-2 border-solid rounded p-4 my-2">
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Writable network share</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">Unauthenticated users can write to the NFS share</h2>
                </div>
                <div class="vx-col w-1/4 flex items-center">
                  <h2 class="font-semibold">HPUX</h2>
                </div>
                <div class="vx-col w-1/4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input4" />
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      //last_drag_error: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'False Positive') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
      }

      if (document.getElementById('input1').textContent === 'False Positive') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
      }

      if (document.getElementById('input2').textContent !== '') {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'False Positive') {
        totalScore++;
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
      }

      if (document.getElementById('input4').textContent !== '') {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
